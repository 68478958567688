import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

class PersonalInfo extends React.Component {
  
  render(){
    const { values, handleChange } = this.props;
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Your Info
        </Typography>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <TextField
              required
              id="firstName"
              name="patientName"
              label="Your Name"
              fullWidth
              autoComplete="fname"
              value={values.patientName}
              onChange={handleChange('patientName')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Mobile No"
              fullWidth
              autoComplete="lname"
              value={values.mobileNo}
              onChange={handleChange('mobileNo')}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default PersonalInfo;