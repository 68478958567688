import React, { Component } from 'react';
import './App.css';
import Appointment from './components/Appointment';

class App extends Component {
  render() {
    return (
      <div>
        <Appointment></Appointment>
      </div>
    
    );
  }
}

export default App;
