import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    typography: {
        fontSize: `11px`
    },
    toolbar : {
        flex: 1,
        justifyContent: 'center'
    },
    footer : {
        position: 'fixed',
        bottom: 0
    }
});

class FooterBar extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" color="inherit" className={classes.typography}>
                    All copyrights reserved © {new Date().getFullYear()} - DataWENS Technologies Pvt Ltd.
                    </Typography>
                </Toolbar>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(FooterBar);
