import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

const addresses = ['1 Material-UI Drive', 'Reactville', 'Anytown', '99999', 'USA'];


const styles = theme => ({
  listItem: {
    padding: `${theme.spacing.unit}px 0`,
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing.unit * 2,
  },
});

class Confirmation extends React.Component {
  render() {
    const { values, classes } = this.props;
    const personInfo = [
      { name: 'Name', detail: values.patientName },
      { name: 'Mobile No', detail: values.mobileNo }
    ];
    return (
      <React.Fragment>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              Doctor details
            </Typography>
            <Typography gutterBottom>John Smith</Typography>
            <Typography gutterBottom>{addresses.join(', ')}</Typography>
            <Grid item container direction="column" xs={12}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                Appointment Details
          </Typography>
              <Grid container>
                  <React.Fragment >
                    <Grid item xs={6}>
                      <Typography gutterBottom>Appointment Date</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography gutterBottom>{moment(values.appointmentDate).format("DD-MM-YYYY")}</Typography>
                    </Grid>
                  </React.Fragment>
                  <React.Fragment >
                    <Grid item xs={6}>
                      <Typography gutterBottom>Session of the Day</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography gutterBottom>{values.session}</Typography>
                    </Grid>
                  </React.Fragment>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={12} sm={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              Your details
          </Typography>
            <Grid container>
              {personInfo.map(payment => (
                <React.Fragment key={payment.name}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{payment.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>{payment.detail}</Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

Confirmation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Confirmation);
