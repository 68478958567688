import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from 'material-ui-pickers';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import deepPurple from '@material-ui/core/colors/deepPurple';
import moment from 'moment';
import green from '@material-ui/core/colors/green';
import Radio from '@material-ui/core/Radio';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const styles = {
  grid: {
    width: '100%',
    justifyContent: 'left'
  },
  avatar: {
    margin: 10,
  },
  purpleAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
  root: {
    color: green[600],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
};



class SelectDate extends React.Component {

  state = {
    selectedDate: new Date(),
    doctors: [],
    page: 0,
    rowsPerPage: 5,
    rows: [],
    doctorAvailable: ''
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  checkAvailable() {
    axios.get(`https://demo.atcurrent.com/Setting/AppApi.aspx?action=IsFreeTime&DomainName=${this.props.values.doctor}&AppointDate=${moment(this.props.values.appointmentDate).format("YYYY-MM-DD")}&SessionPart=${this.props.values.session}`).then(
      (res) => {
        if (res.data.responseType === 'Success') {
          this.setState({
            doctorAvailable: res.data.data
          });
        }
      }
    )
  }


  // changeDate = input => event => {
  //   console.log(moment(event).format("YYYY-MM-DD"));
  //   event.target.propTypes = {
  //     value : (moment(event).format("YYYY-MM-DD"))
  //   };
  //   this.props.handleChange('appointmentDate')
  // }

  componentDidMount() {
    axios.get(`https://demo.atcurrent.com/Setting/AppApi.aspx?action=DocList&city=${this.props.values.city}&exp=${this.props.values.exp}&fee_from=${this.props.values.fromFee}&fee_to=${this.props.values.toFee}`).then(
      (res) => {
        if (res.data.responseType === 'Success') {
          this.setState({
            doctors: res.data.data,
            rows: res.data.data
          });
        }
      }
    )
  }

  render() {
    const { classes, values, handleChange } = this.props;
    const { rows, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const { selectedDate } = this.state;
    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Doctor name</TableCell>
                  <TableCell align="right">Select Doctor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.doctors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <TableRow key={row.User_ID}>
                    <TableCell align="left">
                      <Grid container spacing={24} alignItems="center">
                        <Grid item xs={4}>
                          <Avatar className={classes.purpleAvatar}>{row.consultant_name.substring(0, 2)}</Avatar>
                        </Grid>
                        <Grid item xs={8} >
                          {row.consultant_name}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="right">
                      <Radio
                        checked={values.doctor === row.domain_name}
                        onChange={handleChange('doctor')}
                        value={row.domain_name}
                        name="radio-button-demo"
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                      />
                      {/* <Button variant="contained" size="small" color="primary"  onClick={handleChange('doctor')} value={row.domain_name}>Select Doctor</Button> */}
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={2} > No Doctors found </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={2}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container className={classes.grid} justify="space-around">
              <DatePicker
                align="left"
                margin="normal"
                label="Date picker"
                format="d MMM yyyy"
                minDate={this.state.selectedDate}
                value={values.appointmentDate}
                onChange={handleChange('appointmentDate')}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Session</InputLabel>
            <Select name="state" value={values.session || ''} onChange={handleChange('session')} onBlur={this.checkAvailable()} >
              <MenuItem key="am" value="MORNING">AM</MenuItem>
              <MenuItem key="pm" value="EVENING">PM</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}


SelectDate.propTypes = {
  classes: PropTypes.object.isRequired,
};

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

export default withStyles(styles)(SelectDate);
