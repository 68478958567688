import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';



class FindDoctor extends React.Component {

  state = {
    states: []
  };

  componentDidMount() {
    axios.get('https://demo.atcurrent.com/Setting/AppApi.aspx?action=CityList').then(
      (res) => {
        if (res.data.responseType === 'Success') {
          console.log(res);
          this.setState({
            states: res.data.data
          });
        }
      }
    )
  }

  // handleChange = name => event => {
  //   this.setState({ findDoctorFormValue : { [name]: event.target.value} });
  //   console.log(this.state);
  // };


  render() {
    const { values, handleChange } = this.props;
    let loadStates = this.state.states.map((item, index) => {
      return (<MenuItem key={index} value={item.city}>{item.city}</MenuItem>)
    });
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Find doctors
      </Typography>
      <form autoComplete="off">
        <Grid container spacing={24}>
          <Grid item xs={12}>
          <FormControl fullWidth>
          <InputLabel>State/Province/Region</InputLabel>
            <Select name="state" value={values.city || ''} onChange={handleChange('city')} >
              {loadStates}
            </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="exp"
              name="exp"
              label="Experience"
              fullWidth
              autoComplete="off"
              value={values.exp || ''}
              onChange={handleChange('exp')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              required
              id="fromFee"
              name="fromFee"
              label="Fee From"
              fullWidth
              value={values.fromFee || ''}
              onChange={handleChange('fromFee')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              id="toFee"
              name="toFee"
              label="Fee To"
              fullWidth
              value={values.toFee || ''}
              onChange={handleChange('toFee')}
            />
          </Grid>
        </Grid>
        </form>
      </React.Fragment>
    );
  }
}

export default FindDoctor;